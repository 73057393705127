.billing-manager-banner {
  padding: var(--base-size-32) var(--base-size-16);
  margin-bottom: var(--base-size-32);
  overflow: hidden;
  background: var(--bgColor-muted, var(--color-canvas-subtle));
  border-bottom: $border-width $border-style var(--borderColor-muted, var(--color-border-muted));

  .container {
    position: relative;
  }
}

.billing-manager-banner-text {
  // stylelint-disable-next-line primer/spacing
  margin-left: 210px;
  font-size: $h5-size;
  color: var(--fgColor-muted, var(--color-fg-muted));

  .btn {
    margin-top: var(--base-size-8);
    margin-right: var(--base-size-8);
  }
}

.billing-manager-banner-title {
  font-size: $h6-size;
  font-weight: $font-weight-bold;
  color: var(--fgColor-muted, var(--color-fg-muted));
}

.billing-manager-icon {
  position: absolute;
  // stylelint-disable-next-line primer/spacing
  top: -35px;
  left: 0;
  width: 180px;
  height: 180px;
  // stylelint-disable-next-line primer/typography
  font-size: 180px;
  color: var(--fgColor-muted, var(--color-fg-muted));
}
